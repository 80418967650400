import logo from './logo.svg';
import './App.css';
import { Link, Route, Routes } from 'react-router-dom';
import Webinar1 from "./Pages/Webinar1"

function App() {
  return (
    
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      <Link to="/webinar1">Webinar1</Link>
     
      </header>
    </div>
  );
}

export default App;
